<div class="animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-search"></i>
          Configurações de <strong>Atendimento</strong>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Tipo de Atividade</th>
                <th>Nome da Realização</th>
                <th>Instrumento</th>
                <th>CPF Responsável</th>                
              </tr>
              <tr>
                <th scope="col">&nbsp;</th>
                <th scope="col">
                  <input type="text" class="form-control" [(ngModel)]="Response.NomeRealizacao" placeholder="Buscar..."/>
                </th>
                <th scope="col">
                  <input type="text" class="form-control" [(ngModel)]="Response.Instrumento" placeholder="Buscar..."/>
                </th>
                <th scope="col">
                  <input type="text" class="form-control" [(ngModel)]="Response.CpfResponsavel" placeholder="Buscar..."/>
                </th>                  
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of configuracoes | orderBy: 'id':true | filterBy: Response" (click)="editarRegistro(c.id)" style="cursor: pointer;"
                tooltip="Clique para editar a configuração" placement="left">
                <td>{{c?.conteudo_tipo?.nome}}</td>
                <td>{{c.NomeRealizacao}}</td>
                <td>{{c.Instrumento}}</td>
                <td>{{c.CpfResponsavel}}</td>                
              </tr>
              <tr *ngIf="configuracoes && configuracoes.length == 0">
                <td colspan="4">Não existe configurações cadastradas</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>

</div>