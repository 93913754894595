import { MyNgbDateParserFormatter } from './../../../../shared/datepicker/MyNgbDateParserFormatter';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
//import { MyNgbDateParserFormatter } from '../../../../shared/datepicker/MyNgbDateParserFormatter';
import { CheckCadastroService } from '../../../../services/check-cadastro.service';
import { environment } from '../../../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tab-check-consultas',
  templateUrl: './tab-check-consultas.component.html',
  styleUrls: ['./tab-check-consultas.component.scss'],
  styles: [`:host /deep/ .string {color:blue} ...`]
})
export class TabCheckConsultasComponent implements OnInit {
  @ViewChild('modalVisualizarJson') public modalVisualizarJson: ModalDirective;

  public consultas: any;
  public listOrigensConsulta: any;
  public dataModalJson: any;
  public tituloModalJson: any;
  public isCollapsed: boolean = false;
  public iconCollapse: string = "icon-arrow-up";

  public data_ini: NgbDateStruct = { day: null, month: null, year: null };
  public data_fim: NgbDateStruct = { day: null, month: null, year: null };
  public listTiposDocumento = [{ nome: 'Pessoa Física', value: 'PF' }, { nome: 'Pessoa Jurídica', value: 'PJ' }, { nome: 'Todos', value: '' }];
  public listSimNao = [{ nome: 'Sim', value: 1 }, { nome: 'Não', value: 0 }, { nome: 'Todos', value: '' }];
  public listLocalizados = [{ nome: 'Localizadas', value: 1 }, { nome: 'Não Localizadas', value: 0 }, { nome: 'Todos', value: '' }];    

  public totalItems: number;
  public currentPage: number = 1;
  public maxSize: number;
  public listItemsPerPage = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000];

  public filter: any;

  constructor(private customFormat: MyNgbDateParserFormatter    
    , private checkCadastroService: CheckCadastroService) { }

  ngOnInit() {
    this.checkCadastroService.getListaOrigensConsulta().then(response => {
        this.listOrigensConsulta = response;
    });

    this.initFiltros();
    this.getConsultas();
  }

  initFiltros() {
    const now = new Date();

    //Voltar dias (operador -)
    var date = new Date();    
    const dtInicio = new Date(date.getTime() - (30 * 24 * 60 * 60 * 1000));

    this.data_ini = { year: dtInicio.getFullYear(), month: dtInicio.getMonth() + 1, day: dtInicio.getDate() };
    this.data_fim = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };

    this.filter = {
      tipo_documento: '',
      numero_documento: '',
      CodParceiro: '',
      data_ini_consulta: '',
      data_fim_consulta: '',
      encontrado: '',
      origem_consulta: '',
      status_code_indisponivel: '',      
      cadastro_com_email : '',      
      page: 1,
      limit: 30
    }
  }  

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.iconCollapse = this.isCollapsed ? "icon-arrow-down" : "icon-arrow-up";
  }

  pageChanged(event: any): void {
    this.filter.page = event.page;
    this.filter.limit = event.itemsPerPage;
    this.getConsultas();
  }

  async getConsultas(): Promise<void> {
    //Parser
    this.filter.tipo_documento = this.filter.tipo_documento ? this.filter.tipo_documento : '';
    this.filter.encontrado = this.filter.encontrado == 1 || this.filter.encontrado == 0 ? this.filter.encontrado : '';
    this.filter.origem_consulta = this.filter.origem_consulta ? this.filter.origem_consulta : '';
    this.filter.status_code_indisponivel = this.filter.status_code_indisponivel == 1 || this.filter.status_code_indisponivel == 0 ? this.filter.status_code_indisponivel : '';
    this.filter.cadastro_com_email = this.filter.cadastro_com_email == 1 || this.filter.cadastro_com_email == 0 ? this.filter.cadastro_com_email : '';

    this.filter.data_ini_consulta = this.data_ini ? this.customFormat.formatForServer(this.data_ini) : '';
    this.filter.data_fim_consulta = this.data_fim ? this.customFormat.formatForServer(this.data_fim) : '';        
    
    let response = await this.checkCadastroService.get(this.filter).toPromise();
    this.consultas = response.Consultas;
    this.totalItems = response.TotalItems;

    let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    this.maxSize = maxSize <= 15 ? maxSize : 15;
  }

  pesquisar() {
    this.filter.page = 1;
    this.currentPage = 1;
    this.getConsultas();
  }

  limparFiltros() {
    this.initFiltros();
  }  

  exportar(): void {
    this.filter.exportar_rel = 1;
    let url = environment.environment.nomeServiceCheckCadastro +'/Relatorios/exportarListaConsultasRealizadas.json?'
    
    window.open(url + this.serialize(this.filter, null));
  }  

 
  serialize(obj, prefix) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }
  openModalVisualizarJson(stringJson : any, tituloModal : any) {    
    this.tituloModalJson = tituloModal;
    this.dataModalJson = JSON.parse(stringJson);
    this.modalVisualizarJson.show();
  }

}
