<div class="animated fadeIn">

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-search"></i>
                    Pesquisa de <strong>Lista de Segmentações de Leads</strong>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">Nome da Lista:</label>
                                <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nome do Responsável:</label>
                                <autocomplete-usuario [(ngModel)]="filter" name="filter"></autocomplete-usuario>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Registros por página:</label>

                                <ng-select [items]="listItemsPerPage" placeholder="Selecione..."
                                    [(ngModel)]="filter.limit" name="limit">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group pull-left">
                            <br>
                            <button type="button" class="btn btn-primary" (click)="pesquisar()">
                                <i class="fa fa-search"></i>
                                Pesquisar
                            </button>
                            &nbsp;&nbsp;
                            <a class="btn btn-success" href="#" [routerLink]="['/segmentacao-leads/form/0']">
                                <i class="fa fa-plus"></i> Cadastrar Nova Lista</a>
                            &nbsp;&nbsp;
                            <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                                <i class="fa fa-ban"></i>
                                Limpar Filtros
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i>
                    Resultados ({{segmentacoesLeads && segmentacoesLeads.length ? segmentacoesLeads.length : 0}})
                    <small class="text-muted">(Clique para atualizar o total de leads de 5 minutos em 5 minutos, o
                        sistema mantém o último valor se forçado em um período diferente deste)</small>
                </div>
                <div class="card-body">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th width="5%">&nbsp;</th>
                                <th width="5%">Total Leads</th>
                                <th>Nome da Lista</th>
                                <th>Responsável</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of segmentacoesLeads ; let i = index ">
                                <td class="td-actions text-left">

                                    <div class="btn-group" dropdown placement="bottom left">
                                        <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
                                            Ações
                                            <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item" (click)="exportarLeads(s.id, template)"
                                                    tooltip="Exportar Leads">
                                                    <i class="fa fa-file-excel-o fa-lg mt-1 "></i>Exportar Leads
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item"
                                                    (click)="reprocessarLeads(s)" tooltip="Reprocessar Leads">
                                                    <i class="fa fa-refresh fa-lg mt-1 "></i>Reprocessar Leads
                                                </button>
                                            </li>
                                            <li role="menuitem" *ngIf="executarNovoFluxo">
                                                <button type="button" class="dropdown-item"
                                                        (click)="openModalExecutarFluxo(s)" tooltip="Executar Novo Fluxo">
                                                    <i class="fa fa-play fa-lg mt-1 "></i>Executar Novo Fluxo
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item" (click)="excluirLeads(s)"
                                                    tooltip="Excluir Leads">
                                                    <i class="fa fa-times fa-lg mt-1 "></i>Excluir Leads
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item" (click)="excluirAtendimentosSasLeadsFalha(s)"
                                                        tooltip="Desfazer Atendimentos SAS">
                                                    <i class="fa fa-external-link fa-lg mt-1 "></i>Desfazer Atendimentos SAS
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item"
                                                    (click)="reenviarLeadsTravados(s)" tooltip="Reenviar Leads Travados">
                                                    <i class="fa fa-refresh fa-lg mt-1 "></i>Reenviar Leads Travados
                                                </button>
                                            </li>
                                            <li class="divider dropdown-divider"></li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item"
                                                    (click)="editarRegistro(s.id)" tooltip="Editar">
                                                    <i class="fa fa-pencil fa-lg mt-1 "></i> Editar/Visualizar Lista
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button type="button" class="dropdown-item" (click)="deletar(s)"
                                                    tooltip="Excluir">
                                                    <i class="fa fa-times fa-lg mt-1 "></i> Excluir Lista
                                                </button>
                                            </li>

                                        </ul>
                                    </div>

                                </td>
                                <td (click)="atualizarContador(s?.filtros_json,s?.id)" style="text-align: center">
                                    <div style="cursor: pointer"><span class="badge badge-success"
                                            tooltip="Clique aqui se deseja atualizar o contador, considere atualizações de 5 minutos em 5 minutos">
                                            {{totalLeads[s?.id]}}</span></div>
                                </td>
                                <td>{{s?.nome}}</td>
                                <td>{{s?.usuario_resp?.nome}}</td>
                            </tr>
                            <tr *ngIf="segmentacoesLeads && segmentacoesLeads.length == 0">
                                <td colspan="4">Não existe lista de segmentação de leads com estes parâmetros, verifique os filtros.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="col-md-12 justify-content-center">
                        <pagination [boundaryLinks]="true" [itemsPerPage]="filter.limit" [totalItems]="totalItems"
                            class="text-center" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                            [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                            lastText="&raquo;"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div bsModal #modalExecutarFluxo="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Execução de Novo Fluxo</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12" >
                        <label>Confirma a execução do lead selecionado para um novo fluxo? Você está prestes a executar
                            {{qtdeItem}} item(ns) selecionado(s).</label>
                    </div>
                </div>
                <div class="row"  >
                    <div class="col-md-12" >
                        <div class="form-group">
                            <label>Workflow:</label>

                            <ng-select [items]="workflows" bindLabel="nome" bindValue="id"
                                       placeholder="Selecione um WorkFlow" [(ngModel)]="fluxo"
                                       (change)="mostraWorkflow($event)"
                                       name="workflows">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="fluxo">
                    <div class="col-md-12">
                        <alert type="info">
                            <strong>{{workflow.nome}}:</strong> {{workflow.descricao}}
                        </alert>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="salvarExecutarNovoFluxo()"  >
                    <i class="fa fa-check"></i>
                    Salvar
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="modalExecutarFluxo.hide()">Fechar</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
</ng-template>

<ng-template #template #lgModal>
    <div class="modal-header bg-primary">
        <h4 class="modal-title pull-left">Exportar para Excel</h4>
    </div>
    <div class="modal-body">
        <app-exportar-leads (exportar)="setColunasExport($event)"></app-exportar-leads>
    </div>
    <div class="modal-footer bg-primary">
        <button type="button" class="btn btn-success" (click)="exportCSV(modalRef)">Sim</button>
        <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">Não</button>
    </div>
</ng-template>
