import { Component, OnInit } from '@angular/core';
import { AtendimentoConfiguracaoService } from '../../services/atendimento-configuracao.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-atendimento-configuracoes',
  templateUrl: './atendimento-configuracoes.component.html',
  styleUrls: ['./atendimento-configuracoes.component.scss']
})
export class AtendimentoConfiguracoesComponent implements OnInit {

  public configuracoes: any;
  public Response = { NomeRealizacao: '', Instrumento : '', CpfResponsavel : '' };
  
  constructor(
    private atendimentoConfiguracaoService:AtendimentoConfiguracaoService,
    private router: Router
    ) { }

  async ngOnInit() {
    this.configuracoes = await this.atendimentoConfiguracaoService.get({}).toPromise();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/atendimento-configuracoes/form', id]);
  }

}
