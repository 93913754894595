import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SegmentacaoLeadsRoutingModule } from './segmentacao-leads-routing.module';
import { SegmentacaoLeadsComponent } from './segmentacao-leads.component';
import { SegmentacaoLeadsFormComponent } from './segmentacao-leads-form/segmentacao-leads-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesModule } from '../../shared/control-messages/control-messages.module';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CompleteDigitoCpfModule } from '../../pipe/complete-digito-cpf/complete-digito-cpf.module';
import { CompleteDigitoCnpjModule } from '../../pipe/complete-digito-cnpj/complete-digito-cnpj.module';
import { NgxMaskModule } from 'ngx-mask';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ConfirmModalModule } from '../../shared/confirm-modal/confirm-modal.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutocompleteUsuarioModule } from '../../shared/form-component/autocomplete-usuario/autocomplete-usuario.module';
import { ExportarLeadsModule } from '../../shared/exportar-leads/exportar-leads.module';
import { AlertModule } from 'ngx-bootstrap/alert';
@NgModule({
  declarations: [
    SegmentacaoLeadsComponent,
    SegmentacaoLeadsFormComponent
  ],
  imports: [
    CommonModule,
    SegmentacaoLeadsRoutingModule,
    NgSelectModule,
    NgbModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    ExportarLeadsModule,
    FormsModule,
    ReactiveFormsModule,
    ControlMessagesModule,
    NgbTypeaheadModule,
    CompleteDigitoCpfModule,
    CompleteDigitoCnpjModule,
    NgxMaskModule.forRoot(),
    BsDropdownModule.forRoot(),
    ConfirmModalModule,
    AutocompleteUsuarioModule,
    ModalModule.forRoot(),
    AlertModule,
  ]
})
export class SegmentacaoLeadsModule { }
